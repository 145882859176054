<template>
  <div class="login-container">
    <div class="login-c-box">
      <el-form :rules="rules" :model="loginForm" class="loginRoot">
        <h3 class="loginTitle">系统登录</h3>
        <el-form-item prop="username">
          <el-input type="text" v-model="loginForm.username" auto-complete="off" placeholder="请输入手机号/账号" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="loginForm.password" auto-complete="off" placeholder="请输入密码" />
        </el-form-item>
        <el-button @click="login" type="primary" style="width: 100%">登录</el-button>

        <div class="flex-row align-center justify-between">
          <div class="mt-2" @click="goRegister">注册账号</div>
          <div class="mt-2" @click="goRegister(2)">忘记密码</div>
        </div>
      </el-form>
    </div>
    <div class="login-foot-title">
      Copyright ©2018-2020 深圳市新系区块链技术有限公司 粤ICP备19004762号
    </div>
  </div>
</template>
 
<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapMutations(["$_setStorage", "setUserInfo"]),
    async login() {
      if (this.loginForm.username === "" || this.loginForm.password === "") {
        alert("账号或密码不能为空");
      } else {
        const getData = await this.$send.login(this.loginForm);
        if (getData.code == 0 && getData.data && getData.data.token) {
          this.setUserInfo(getData.data);
          this.$_setStorage(getData.data.token);
          /*
            关于用户数据：
            1-用户首次登录或者未录入过用户数据，提示用户为了更好的提供服务，需要录入以下信息：性别、年龄、身高、体重
            2-如果用户下次登录超过一年，提示用户更新身高和体重信息，年龄自动加
          */
          if (!getData.data.height || !getData.data.weight || !getData.data.birthday) {
            this.$router.replace("/userInfo");
            return false;
          }
          if (getData.data.lastLoginTime) {
            if (new Date().getTime() - new Date(getData.data.lastLoginTime).getTime() > 365 * 24 * 60 * 60 * 1000) {
              this.$router.replace("/userInfo");
              return false;
            }
          }
          this.$router.replace("/home");
        } else {
          alert("账号或密码错误1");
        }
      }
    },
    goRegister(type = 1) {
      if(type == 2) {
        this.$router.replace("/register?type=2");
      }else {
        this.$router.replace("/register");
      }
    }
  },
};
</script>
<style>
.login-container {
  height: 100vh;
  background: url("../assets/banner4.png") no-repeat center fixed;
  background-size: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.login-c-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginRoot {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 0px auto;
  width: 350px;
  padding: 50px 50px 20px 50px;
  background: aliceblue;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #c6c6c6;
}

.loginTitle {
  margin: 15px auto 40px auto;
  text-align: center;
  color: #505050;
}

.logins-container {
  width: 100%;
  height: 100%;
  background-image: url("../assets/banner4.png");
  background-size: cover;
  background-position: center;
  position: relative;
}

.login-foot-title {
  font-size: 14px;
  color: #ffffff;
}
</style>
