//当前项目的路由模块

//1.导入Vue和VueRouter的包
//import Vue from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import login from '@/components/login';
import register from '@/components/register';
import home from '@/components/home';
import userInfo from '@/components/userInfo';
import otherOpen from '@/components/otherOpen';
//2.调用Vue.use()函数，把VueRouter安装为Vue插件
//Vue.use(Router)
//3.创建路由的实例对象
const router = createRouter({
	history: createWebHashHistory(),
  routes: [
    {
		path: '/',
		redirect: '/login',
		component: login
	},
	
        //登录路由规则
    {
      path: '/login',
      name: 'login',
      component: login
    },
    // 注册用户
    {
      path: '/register',
      name: 'register',
      component: register
    },
	   
        // 界面路由
    {
      path: '/home',
      name: 'home',
      component: home
    },
    {
      path: '/userInfo',
      name: 'userInfo',
      component: userInfo
    },
    {
      path: '/otherOpen',
      name: 'otherOpen',
      component: otherOpen
    }
  ]
});
//导航守卫
//使用router.beforeEach注册一个全局前置守卫，判断用户是否登录
router.beforeEach((to,from,next)=>{
	//to 将要访问的路径
  //from 代表从哪个路径而来
  //next() 代表放行 next('xxx') 强制放行的xxx的路径
  
  if(to.path === '/login'){
    next();
  }else{
    let token = localStorage.getItem('Authorization');
    if( token === 'null' || token === ''){
      next('/login')
    }else{
      next();
    }
  }
});

//4.向外共享路由的实例对象
export default router;
