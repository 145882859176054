import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' //导入store


// 引入公共样式
import '@/common/css/page.css';
import '@/common/css/public.css';

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

app.use(ElementPlus, {
  locale: zhCn,
});

import { sendAPI } from '@/common/api';

app.config.globalProperties.$send = sendAPI;




app.use(router);
app.use(store);
app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
} 

