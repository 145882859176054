import axios from 'axios';
import appConfig from './config';
import store from '../store';
import router from '../router';

export const request = async (url, data = {}, method = 'POST') => {
    url = appConfig.BASR_URL + url;
    method = method.toUpperCase(); // 小写转大写

    // 请求头
    let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "token": store.state.token
    }
    try {
        const res = await axios.request({
            url,
            method,
            headers,
            data: method == 'GET' ? {} : data,
            params: method == 'GET' ? data : {},
            responseType: 'json'
        })
        const getData = res.data;

        return getData;
    } catch (err) {
        const errData = err.response.data;
        if (errData.code) {
            if (errData.code == 401) {
                store.dispatch('setUserInfo', '');
                router.replace('/login');
            }
            return errData;
        } else {
            return {
                code: err.response.status,
                message: errData.message
            }
        }
    }

}