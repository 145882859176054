<!-- views/chatgpt.vue -->

<template>
  <div class="flex-column align-center">
    <div class="flex-column lt-page-box">
      <h1>中医GPT助手</h1>
      <div class="flex-1 overflow-hidden">
        <div id="contentPage" class="content-page px-2" style="height: 100%;overflow-y:scroll;">
          <div class="flex-column align-center py-1" v-if="pageObj.haveMore">
            <span class="color-blue" style="font-size: 15px;" @click="getMoreHistory">查看更多聊天记录</span>
          </div>
          <div v-for="(item, idx) in chatRecord" :key="idx">
            <div v-if="item.isAi" class="flex-row align-start mb-2">
              <div class="mr-2">
                <img style="width: 40px;height:40px;border-radius:50%" src="@/assets/ai-img.jpg" alt="" />
              </div>
              <div class="ba-black-q px-1 py-1 radius-1 text-left" style="max-width: 70%;" v-html="item.message"></div>
            </div>
            <div v-else class="flex-row align-start justify-end mb-2">
              <div class="bg-green px-1 py-1 radius-1 text-left" style="max-width: 70%;" v-html="item.message"></div>
              <div class="ml-2">
                <img style="width: 40px;height:40px;border-radius:50%" src="@/assets/user-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row align-start py-2 px-2">
        <el-input v-model="question" type="textarea" @keydown="handleKeyCode($event)" :autosize="{ minRows: 2, maxRows: 15 }" placeholder="请输入你的问题" resize="none" show-word-limit maxlength="1000" />
        <div class="ml-2" style="text-align: right;margin-bottom:0px">
          <el-button color="#51FF50" @click="chatWebsocket" style="margin: 4px 0;width:50px;" size="large" :disabled="click">发送</el-button>
        </div>
      </div>
    </div>

    <!-- <el-container style="width: 80vw;background-color: #FFFFFF;">
      <el-header>
        <h1>中医GPT助手</h1>
      </el-header>
      <el-container>
        <el-main>
          <el-descriptions column=1 size="small" border="true">
            <div v-for="(item, idx) in chatRecord" :key="item">
              <el-descriptions-item :label="item.sender" :label-class-name="idx % 2 == 0 ? 'bg-red' : 'bg-green'"
                content-class-name="bg-green" label-align="center">
                <div v-html=item.message :class="idx % 2 == 0 ? 'bg-red' : 'bg-green'"></div>
              </el-descriptions-item>
            </div>
          </el-descriptions>
        </el-main>
        <el-footer style="padding:10px;height:200px;">
          <div class="fixedElement">
            <el-input v-model="question" type="textarea" @keydown="handleKeyCode($event)" placeholder="请输入你的问题"
              :autosize="{ minRows: 3, maxRows: 5 }" resize="none" show-word-limit maxlength="300" />
            <div style="text-align: right;margin-bottom:0px">
              <el-button color="#8d3d45" @click="chat" plain style="margin: 4px 0;width:50px;" size="large"
                :disabled="click">提交</el-button>
            </div>
          </div>
        </el-footer>
      </el-container>
    </el-container> -->
  </div>
</template>

<script>
// important { EventSourcePolyfill } from 'event-source-polyfill';
import { mapMutations, mapGetters } from "vuex";

import appConfig from "@/common/config";

export default {
  name: "ChatGPT",
  data() {
    return {
      uid: "",
      question: "",
      count: 0,
      click: false,
      socket: "",
      pageObj: {
        pageSize: 1000,
        pageNum: 1,
        time: "",
        getNew: false,
        haveMore: true,
      },

      chatRecord: [
        {
          isAi: true,
          sender: "AI:",
          message: "您好，主人！有什么需要向我提问的吗？",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  async created() {
    this.initUid();
    this.pageObj.pageNum = 1;
    this.pageObj.time = new Date().getTime();
    await this.getHistoryChat();
    setTimeout(() => {
      // 带问题进入则直接自动发送
      let question = window.sessionStorage.getItem("_question");
      if (question) {
        window.sessionStorage.setItem("_question", "");

        this.question = question;
        this.chatWebsocket();
      }
    }, 500);
  },
  methods: {
    initUid() {
      let uid = window.localStorage.getItem("uid");
      if (uid == null || uid == "" || uid == "null") {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        uid = s.join("");
      }
      // 设置本地存储
      window.localStorage.setItem("uid", uid);
      this.uid = uid;

      if (typeof WebSocket == "undefined") {
        console.log("您的浏览器不支持WebSocket");
      } else {
        //实现化WebSocket对象
        //指定要连接的服务器地址与端口建立连接
        //注意ws、wss使用不同的端口。我使用自签名的证书测试，
        //无法使用wss，浏览器打开WebSocket时报错
        //ws对应http、wss对应https。
        // socket = new WebSocket("ws://localhost:8000/websocket/"+uid);
        this.socket = new WebSocket(
          `${appConfig.WEBSOCKET_URL}/websocket/${
            this.uid
          }?token=${encodeURIComponent(this.$store.state.token)}`
        );
        // this.socket = new WebSocket(`ws://218.17.167.88:9000/websocket/${this.uid}?token=${encodeURIComponent(this.$store.state.token)}`);
        //连接打开事件
        this.socket.onopen = function () {
          console.log("Socket 已打开");
        };
        //收到消息事件
        this.socket.onmessage = (msg) => {
          // 设置滚动条每次都是再底部
          const contentPage = document.getElementById("contentPage");
          contentPage.scrollTop = contentPage.scrollHeight;

          var ai = this.chatRecord[this.chatRecord.length - 1];
          if (msg.data == "[DONE]") {
            return;
          }
		  if (msg.data==""){
		   return;
		  }
          let json_data = JSON.parse(msg.data);
		  
		  if (json_data.errormessage){
		    //if(json_data.errormessage!= null || json_data.errormessage != "null") {
             alert(json_data.errormessage);
           // }
		  }
		  
          if (json_data.content == null || json_data.content == "null") {
            return;
          }
		  
		  
          ai.message += json_data.content;
          ai.message = ai.message.replace(/\n/g, "<br>");
          this.chatRecord[this.chatRecord.length - 1] = ai;
        };
        //连接关闭事件
        this.socket.onclose = function () {
          console.log("Socket已关闭");
        };
        //发生了错误事件
        this.socket.onerror = function () {
          alert("服务异常请重试并联系开发者！");
        };
        //窗口关闭时，关闭连接
        window.unload = () => {
          this.socket.close();
        };
      }
    },
    chatWebsocket() {
      var you = {
        isAi: false,
        sender: "YOU:",
        message: this.question.replace(/\n/g, "<br>"),
      };
      var ai = { isAi: true, sender: "AI:", message: "" };
      this.chatRecord.push(you);
      this.chatRecord.push(ai);
      // 设置滚动条每次都是再底部
      this.$nextTick(() => {
        const contentPage = document.getElementById("contentPage");
        contentPage.scrollTop = contentPage.scrollHeight;
      });

      if (this.socket.readyState != this.socket.OPEN) {
	  
	  // 重连
	     this.socket.close()
		 console.log('websocket连接已关闭');
	    this.initUid()
				
        //alert("连接已中断,请重新登录或刷新当前页面!");
        //this.$store.dispatch("setUserInfo", "");
        //this.$router.replace("/login");
        //todo...
        return true;
      }

      this.socket.send(this.question);
      this.question = "";
    },

    handleKeyCode(event) {
      if (event.keyCode == 13) {
        if (!event.ctrlKey) {
          event.preventDefault();
          this.handleTextSend();
        } else {
          this.question = this.question + "\n";
        }
      }
    },
    handleTextSend() {
      this.chatWebsocket();
    },
    // 获取聊天记录
    async getHistoryChat() {
      let pageNum = this.pageObj.pageNum;
      let sendData = {
        pageNum,
        pageSize: this.pageObj.pageSize,
        uid: this.uid,
        time: this.pageObj.time,
        token: this.getUserInfo.token,
      };
      const getData = await this.$send.getChatgptAnnImHistoryList(sendData);
      if (getData.code == 0) {
        let list = [];
        if (getData.data.records) {
          getData.data.records.forEach((item) => {
            let obj = { isAi: false, sender: "YOU:", message: item.content };
            if (item.uid != this.uid) {
              obj.isAi = true;
              obj.sender = "AI:";
            }
            list.unshift(obj);
          });
        }
        if (list.length < this.pageObj.pageSize) {
          this.pageObj.haveMore = false;
        }
        if (pageNum == 1) {
          list.push({
            isAi: true,
            sender: "AI:",
            message: "您好，主人！有什么需要向我提问的吗？",
          });
          this.chatRecord = list;
          this.$nextTick(() => {
            // 设置滚动条每次都是再底部
            const contentPage = document.getElementById("contentPage");
            contentPage.scrollTop = contentPage.scrollHeight;
          });
        } else {
          if (list.length <= 0) {
            this.pageObj.pageNum--;
          } else {
            // 获取当前高度
            const contentPage = document.getElementById("contentPage");
            const scrollHeight = contentPage.scrollHeight;
            console.log("contentPage.scrollHeight---", scrollHeight);

            this.chatRecord = list.concat(this.chatRecord);
            this.$nextTick(() => {
              // 设置滚动条每次都是再底部
              const contentPage1 = document.getElementById("contentPage");
              console.log(
                "contentPage1.scrollHeight---",
                contentPage1.scrollHeight
              );
              contentPage.scrollTop = contentPage1.scrollHeight - scrollHeight;
            });
          }
        }
      } else {
        alert("获取聊天记录失败");
      }
    },
    // 查看更多聊天记录
    async getMoreHistory() {
      if (this.pageObj.getNew) {
        return false;
      }
      this.pageObj.pageNum++;
      this.pageObj.getNew = true;

      await this.getHistoryChat();
      this.pageObj.getNew = false;
    },
  },
};
</script>

<style>
@media screen and (max-width: 749px) {
  .lt-page-box {
    background-color: rgba(0, 0, 0, 0.04);
    width: 100vw;
    height: 100vh;
  }
}

@media screen and (min-width: 750px) and (max-width: 949px) {
  .lt-page-box {
    background-color: rgba(0, 0, 0, 0.04);
    width: 80vw;
    height: 100vh;
  }
}

@media screen and (min-width: 950px) {
  .lt-page-box {
    background-color: rgba(0, 0, 0, 0.04);
    width: 60vw;
    height: 100vh;
  }
}

.page-box-foot {
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 30px 0;
  border-top: 1px solid #323233;
}

.bg-red {
  background-color: #ffffff !important;
}

.bg-green {
  background-color: #51ff50 !important;
}

.fixedElement {
  background-color: #fffffe;
  position: fixed;
  display: flex;
  /* 从左到右 */
  /* flex-direction:row;  */
  /* 从右到左 */
  /* flex-direction:row-reverse; */
  /* 从上到下 */
  /* flex-direction:column; */
  /* 从下到上 */
  bottom: 0;
  z-index: 99;
}
</style>