<template>
  <div>
  </div>
</template>
    
   <script>
import { mapMutations, mapGetters } from "vuex";
import appConfig from "@/common/config";
export default {
  components: {},
  data() {
    return {
      token: ''
    };
  },
  created() {
    if(this.$route.query.token) {
        this.token = this.$route.query.token;
        this.$_setStorage(this.token);
        if(this.$route.query.question) {
          window.sessionStorage.setItem('_question', this.$route.query.question);
        }
        this.getUserInfo();
    }else {
        alert('必传参数为空');
    }
  },
  methods: {
    ...mapMutations(["$_setStorage", "setUserInfo"]),
    async getUserInfo() {
      const getData = await this.$send.getUserInfo();
      console.log('回去用户信息--', getData);
      if(getData.code == 0) {
        getData.data.token = this.token;
        this.setUserInfo(getData.data);
        /*
            关于用户数据：
            1-用户首次登录或者未录入过用户数据，提示用户为了更好的提供服务，需要录入以下信息：性别、年龄、身高、体重
            2-如果用户下次登录超过一年，提示用户更新身高和体重信息，年龄自动加
        */
          if (!getData.data.height || !getData.data.weight || !getData.data.birthday) {
            this.$router.replace("/userInfo");
            return false;
          }
          if (getData.data.lastLoginTime) {
            if (new Date().getTime() - new Date(getData.data.lastLoginTime).getTime() > 365 * 24 * 60 * 60 * 1000) {
              this.$router.replace("/userInfo");
              return false;
            }
          }
          this.$router.replace("/home");
      }
    },
  },
};
</script>
<style scoped>
</style>