<template>
  <div class="page-box page flex-column align-center justify-center">
    <div class="userInfo-box pt-3 pb-4">
      <div class="text-title font-weight-bold">用户信息</div>
      <div class="px-2 mt-2">
        <div class="flex-row align-center">
          身高：
          <el-input class="flex-1" v-model="formData.height" type="number" placeholder="请输入身高">
            <template #append>cm</template>
          </el-input>
        </div>
        <div class="mt-2 flex-row align-center">
          体重：
          <el-input class="flex-1" v-model="formData.weight" type="number" placeholder="请输入体重">
            <template #append>kg</template>
          </el-input>
        </div>
        <div class="mt-2 flex-row align-center">
          生日：
          <el-date-picker format="YYYY-MM-DD" value-format="YYYY-MM-DD" v-model="formData.birthday" class="flex-1" type="date" placeholder="请选择生日" />
        </div>
        <div class="mt-2 flex-row align-center">
          性别：
          <el-radio-group v-model="formData.sex">
            <el-radio label="1">男</el-radio>
            <el-radio label="0">女</el-radio>
          </el-radio-group>
        </div>

        <el-button class="mt-3" @click="doChange" type="primary" style="width: 100%">提交</el-button>
      </div>
    </div>
  </div>
</template>
  
 <script>
import { mapMutations, mapGetters } from "vuex";
import { formatDate } from '@/common/public';
export default {
  components: {},
  data() {
    return {
      formData: {
        birthday: "", // 生日
        height: "", // 身高
        weight: "", // 体重
        sex: "", // 性别
      },
    };
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  created() {
    console.log("用户信息--", this.getUserInfo);
    if (this.getUserInfo && this.getUserInfo.token) {
      let birthday = this.getUserInfo.birthday || "";
      if(birthday) {
        birthday = formatDate(new Date(this.getUserInfo.birthday));
      }
      this.formData.birthday = birthday;
      this.formData.height = this.getUserInfo.height || "";
      this.formData.weight = this.getUserInfo.weight || "";
      this.formData.sex = this.getUserInfo.sex ? this.getUserInfo.sex + ''  : "";
    }
  },
  methods: {
    async doChange() {
      if (!this.formData.height) {
        alert("请输入身高");
        return false;
      }
      if (!this.formData.weight) {
        alert("请输入体重");
        return false;
      }
      if (!this.formData.birthday) {
        alert("请选择生日");
        return false;
      }
      if (this.formData.sex === "") {
        alert("请选择性别");
        return false;
      }

      const getData = await this.$send.setUserInfo(this.formData);
      if (getData.code == 0) {
        this.$router.replace("/home");
      } else {
        alert("修改信息错误，请重试");
      }
    },
  },
};
</script>
 <style scoped>
.userInfo-box {
  width: 350px;
  min-width: 400px;
  border-radius: 10px;
  background: aliceblue;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #c6c6c6;
}
</style>