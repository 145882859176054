<template>
   <ChatGPT/>
</template>
 
<script>
import ChatGPT from './ChatGPT.vue'
export default {
components: {
    ChatGPT
  }
}
</script>
<style>
</style>