<template>
  <div class="bg-img-page flex-column align-center justify-center">
    <div class="userInfo-box pt-3 pb-4">
      <div class="text-title font-weight-bold" v-if="type == 2">修改密码</div>
      <div class="text-title font-weight-bold" v-else>注册用户</div>
      <div class="px-2 mt-2">
        <div class="flex-row align-center mb-2" v-if="type != 2">
          <div class="text-left" style="width:85px">身份：</div>
          <el-radio-group v-model="formData.roleType">
            <el-radio :label="0">普通用户</el-radio>
            <el-radio :label="1">医生</el-radio>
            <el-radio :label="2">护士</el-radio>
          </el-radio-group>
        </div>
        <div class="flex-row align-center">
          <div class="text-left" style="width:85px">手机号：</div>
          <el-input class="flex-1" v-model="formData.mobile" type="text" placeholder="请输入..."></el-input>
        </div>
        <div class="mt-2 flex-row align-center">
          <div class="text-left" style="width:85px">验证码：</div>
          <el-input class="flex-1" v-model="formData.verifyCode" type="text" placeholder="请输入...">
            <template #append>
              <el-button v-if="timeNum <= 0" @click="getCode" type="primary" style="color: #409eff" text>获取验证码</el-button>
              <el-button v-else type="primary" text>{{timeNum}}s</el-button>
            </template>
          </el-input>
        </div>
        <div class="mt-2 flex-row align-center">
          <div class="text-left" style="width:85px">新密码：</div>
          <el-input class="flex-1" autocomplete="new-password" v-model="formData.password" type="password" placeholder="请输入..."></el-input>
        </div>
        <div class="mt-2 flex-row align-center">
          <div class="text-left" style="width:85px">确认密码：</div>
          <el-input class="flex-1" autocomplete="new-password" v-model="formData.repassword" type="password" placeholder="请输入..."></el-input>
        </div>

        <el-button class="mt-3" @click="changePassword" type="primary" style="width: 100%" v-if="type == 2">提交</el-button>
        <el-button class="mt-3" @click="doChange" type="primary" style="width: 100%" v-else>注册</el-button>
        <div class="mt-2" @click="$router.replace('/login')">已有账号，我要去登录</div>
      </div>
    </div>
  </div>
</template>
    
   <script>
import { mapMutations, mapGetters } from "vuex";
import { formatDate } from "@/common/public";
export default {
  components: {},
  data() {
    return {
      type: 1, // 1-注册账号 2-忘记密码
      formData: {
        roleType: 0,
        mobile: "", // 手机号
        verifyCode: "", // 验证码
        password: "", // 密码
        repassword: "", // 确认密码
      },
      timeNum: 0,
      timeId: "",
    };
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  created() {
    if (this.$route.query.type == 2) {
      this.type = 2;
    } else {
      this.type = 1;
    }
  },
  methods: {
    async doChange() {
      if (!this.formData.mobile) {
        alert("请输入手机号");
        return false;
      }
      if (!this.formData.verifyCode) {
        alert("请输入验证码");
        return false;
      }
      if (!this.formData.password) {
        alert("请输入新密码");
        return false;
      }
      if (!this.formData.repassword) {
        alert("请输入确认密码");
        return false;
      }
      const getData = await this.$send.register(this.formData);
      if (getData.code == 0) {
        alert("注册成功，立即去登录");
        this.$router.replace("/login");
      } else {
        alert(getData.message || "信息错误，请重试");
      }
    },
    async changePassword() {
      if (!this.formData.mobile) {
        alert("请输入手机号");
        return false;
      }
      if (!this.formData.verifyCode) {
        alert("请输入验证码");
        return false;
      }
      if (!this.formData.password) {
        alert("请输入新密码");
        return false;
      }
      if (!this.formData.repassword) {
        alert("请输入确认密码");
        return false;
      }
      const getData = await this.$send.modifypass(this.formData);
      if (getData.code == 0) {
        console.log(getData);
        alert("修改密码成功，立即去登录");
        this.$router.replace("/login");
      } else {
        alert(getData.message || "信息错误，请重试");
      }
    },
    async getCode() {
      if (this.timeNum > 0) {
        return false;
      }
      if (!this.formData.mobile) {
        alert("请输入手机号");
        return false;
      }
      if (!/^1[34578]{1}\d{9}$/.test(this.formData.mobile)) {
        alert("请输入正确的手机号");
        return false;
      }
      const getData = await this.$send.loginverifyCode({
        mobile: this.formData.mobile,
        type: '0' + this.type
      });
      if (getData.code == 0) {
        this.timeNum = 60;
        clearInterval(this.timeId);
        this.timeId = setInterval(() => {
          this.timeNum = this.timeNum - 1;
          if (this.timeNum <= 0) {
            this.timeNum = 0;
            clearInterval(this.timeId);
          }
        }, 1000);
      } else {
        alert(getData.message);
      }
    },
  },
};
</script>
<style scoped>
.bg-img-page {
  background: url("../assets/banner4.png");
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
}

.userInfo-box {
  width: 350px;
  min-width: 400px;
  border-radius: 10px;
  background: aliceblue;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #c6c6c6;
}
</style>