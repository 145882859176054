import { request } from "./request";

export const sendAPI = {
    login: async (data)=> request('/wslogin', data, 'POST'), // 登录
    setUserInfo: async (data)=> request('/updateuser', data, 'POST'), // 完善用户信息
    getUserInfo: async (data)=> request('/userbytoken', data, 'GET'), // 获取用户信息
    getChatgptAnnImHistoryList: async (data)=> request('/chatgptAnnIm/getChatgptAnnImHistoryList', data, 'GET'), // 获取聊天记录
    loginverifyCode: async (data)=> request('/loginverifyCode', data, 'GET'), // 获取验证码
    register: async (data)=> request('/registernew', data, 'POST'), // 注册用户
    modifypass: async (data)=> request('/modifypass', data, 'POST'), // 忘记密码
}