export default {
    // 本地
  //    BASR_URL: 'http://172.16.77.124:9000', // 接口地址
  //   WEBSOCKET_URL: 'ws://172.16.77.124:9000', // websocket地址
	 
	 
	//   BASR_URL: 'http://172.16.77.17:9000', // 接口地址
     //  WEBSOCKET_URL: 'ws://172.16.77.17:9000', // websocket地址

    // 线上
    BASR_URL: 'https://chatgpt.nrblockchain.com/api',  // 接口地址
    WEBSOCKET_URL: 'wss://chatgpt.nrblockchain.com/webs',// websocket地址
	
	// 本地
   // BASR_URL: 'http://218.17.167.88:9000',  // 接口地址
	// WEBSOCKET_URL: 'wss://218.17.167.88:9000',// websocket地址
}