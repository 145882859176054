import Vuex from 'vuex'

const key = 'token'
const store = new Vuex.Store({
    state () {
        return {
          token: localStorage.getItem('token')?localStorage.getItem('token'):'',
          userInfo: {}
        }
    },
    getters: {
        getSortage: function(state) {
            if(!state.token){
                state.token =JSON.parse(localStorage.getItem(key))
            }
            return state.token
        },
        getUserInfo:(state)=> {
            if(!state.userInfo.token) {
                let userInfo = localStorage.getItem('userInfo');
                if(userInfo) {
                    state.userInfo = JSON.parse(userInfo);
                }
            }
            return state.userInfo;
        }
    },
    mutations: {
        $_setStorage(state,value){
            state.token = value
            localStorage.setItem(key,value)
        },
        $_removeStorage(state){
            state.token = null;
            localStorage.removeItem(key)
        },
        setUserInfo(state, value) {
            if(value) {
                state.userInfo = value;
                localStorage.setItem('userInfo',JSON.stringify(value));
            }else {
                state.userInfo = {};
                localStorage.setItem('userInfo','');
                localStorage.setItem('token','');
            }
        }
    }
})
export default store;